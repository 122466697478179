import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Button from 'components/shared/button';
import Link from 'components/shared/link';

import shape from './images/shape.svg';
import dots from './images/dots-pattren.svg';

import styles from './pdf-banner-with-button.module.scss';

const cx = classNames.bind(styles);

const PdfBannerWithButton = ({ title, description, url, buttonText }) => (
  <section className={cx('wrapper')}>
    <div className={cx('content')}>
      <Link className={cx('title-wrapper')} to={url}>
        <Heading className={cx('title')} size="lg">
          {title}
        </Heading>
      </Link>
      <p className={cx('description')}>{description}</p>
      <img className={cx('shape')} src={shape} alt="" loading="lazy" aria-hidden />
    </div>
    <Button className={cx('button')} to={url} theme="accent-primary" size="sm">
      {buttonText}
    </Button>

    <img className={cx('dots')} src={dots} alt="" loading="lazy" aria-hidden />
  </section>
);

PdfBannerWithButton.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default PdfBannerWithButton;

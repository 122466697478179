import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from 'react-use';

import sendGravityFormData from 'utils/send-gravity-form-data';

import Heading from 'components/shared/heading';
import Input from 'components/shared/input';
import Button from 'components/shared/button';
import FormNotice from 'components/shared/form-notice';
import HubspotForm from 'components/shared/hubspot-form/hubspot-form';

import { DOWNLOAD_THE_GUIDE_FORM_ID } from 'constants';
import sendGtagEvent from 'utils/send-gtag-event';
import CheckIcon from './images/check.inline.svg';

import styles from './form.module.scss';

const cx = classNames.bind(styles);

const validationSchema = yup.object().shape({
  first_name: yup.string().trim().required('First name is a required field'),
  last_name: yup.string().trim().required('Last name is a required field'),
  email: yup.string().trim().email('Must be a valid email').required('Email is a required field'),
});

// It is used for proper loading animation because most of the time we get response from the server almost immediately
const APPEAR_AND_EXIT_ANIMATION_DURATION = 0.5; // seconds
const REDIRECT_DELAY = 2000; // milliseconds

const Form = (props) => {
  const {
    postId,
    title,
    buttonText,
    pageUrl,
    fileUrl,
    displayFollowUpPage,
    followUpRedirectUrl,
    successMessage: { title: messageTitle, description: messageDescription },
    formId: HubspotFormId,
  } = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  // HubSpot cookie tracking
  // read more: https://legacydocs.hubspot.com/docs/methods/forms/submit_form
  const [hubspotutk] = useCookie('hubspotutk');

  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pdf-page/hero/illustration.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 265)
        }
      }
    }
  `);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const gclid = localStorage.getItem('gclid')
      ? JSON.parse(localStorage.getItem('gclid')).value
      : '';
    const slcode = localStorage.getItem('slcode')
      ? JSON.parse(localStorage.getItem('slcode')).value
      : '';
    // GTAG Custom Event
    sendGtagEvent('successful-validation-download-form');
    try {
      await sendGravityFormData(DOWNLOAD_THE_GUIDE_FORM_ID, {
        input_10: values.first_name,
        input_11: values.last_name,
        input_2: values.email,
        input_3: values.company,
        input_7: postId,
        input_8: pageUrl,
        input_9: fileUrl,
        input_13: gclid,
        input_14: slcode,
        input_15: hubspotutk,
      });

      setIsLoading(false);
      setServerResponse('success');

      if (displayFollowUpPage && followUpRedirectUrl) {
        setTimeout(() => {
          navigate(followUpRedirectUrl, {
            state: {
              formId: DOWNLOAD_THE_GUIDE_FORM_ID,
              formFields: {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                company: values.company,
                gclid,
                slcode,
                hubspotutk,
              },
            },
          });
        }, REDIRECT_DELAY);
      }

      localStorage.removeItem('gclid');
      localStorage.removeItem('slcode');
    } catch {
      setIsLoading(false);
      setServerResponse('error');
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('illustration-wrapper')}>
        <GatsbyImage loading="eager" image={getImage(illustration)} alt="" />
      </div>
      <div className={cx('form-wrapper')}>
        <motion.div
          className={cx('inner')}
          animate={
            serverResponse === 'success' && {
              opacity: 0,
              visibility: 'hidden',
              transition: { duration: APPEAR_AND_EXIT_ANIMATION_DURATION },
            }
          }
        >
          <Heading className={cx('title')} size="lg">
            {title}
          </Heading>

          {HubspotFormId && (
            <HubspotForm targetId="hubspotFormPdfPageHero" formId={HubspotFormId} />
          )}

          {!HubspotFormId && (
            <form className={cx('form')} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Input
                className={cx('input')}
                name="first_name"
                placeholder="First name..."
                autoComplete="given-name"
                error={errors?.first_name?.message}
                withBorder
                requiredStyle
                ref={register}
              />
              <Input
                className={cx('input')}
                name="last_name"
                placeholder="Last name..."
                autoComplete="family-name"
                error={errors?.last_name?.message}
                withBorder
                requiredStyle
                ref={register}
              />
              <Input
                className={cx('input')}
                name="email"
                type="email"
                placeholder="Email..."
                autoComplete="email"
                error={errors?.email?.message}
                withBorder
                requiredStyle
                ref={register}
              />
              <Input
                className={cx('input')}
                name="company"
                placeholder="Company"
                error={errors?.company?.message}
                withBorder
                ref={register}
              />

              <Button
                className={cx('button')}
                type="submit"
                theme="accent-primary"
                loading={isLoading}
              >
                {buttonText}
              </Button>
              <FormNotice className={cx('form-notice')} />
            </form>
          )}
        </motion.div>

        {serverResponse === 'success' && (
          <motion.div
            className={cx('message')}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: APPEAR_AND_EXIT_ANIMATION_DURATION },
            }}
            aria-hidden
          >
            <CheckIcon className={cx('message__icon')} />
            <Heading className={cx('message__title')} tag="p" size="lg">
              {messageTitle}
            </Heading>
            <p className={cx('message__description')}>{messageDescription}</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

Form.propTypes = {
  postId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  successMessage: PropTypes.objectOf(PropTypes.any),
  displayFollowUpPage: PropTypes.bool,
  followUpRedirectUrl: PropTypes.string.isRequired,
  formId: PropTypes.string,
};

Form.defaultProps = {
  displayFollowUpPage: false,
  formId: null,
  successMessage: {
    title: 'Thanks!',
    description: 'Check your email inbox for the PDF download',
  },
};

export default Form;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import MainContext from 'context/main';

import styles from './content.module.scss';

const cx = classNames.bind(styles);

const Content = ({ content }) => {
  const { isTableOfContent } = useContext(MainContext);

  return (
    <article className={cx('wrapper')}>
      <div className="container">
        <div className={cx('content', { isTableOfContent })}>{content}</div>
      </div>
    </article>
  );
};

Content.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Content;

/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from 'react';
import { graphql } from 'gatsby';
import parse, { attributesToProps } from 'html-react-parser';

import useImageGallery from 'hooks/use-image-gallery';
import useIntersectionObserver from 'hooks/use-intersection-observer';

import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/pdf-page/hero';
import Content from 'components/pages/pdf-page/content';
import HighlightedList from 'components/lazy-blocks/highlighted-list';
import CompactTitleAndVideo from 'components/lazy-blocks/compact-title-and-video';
import PdfBannerWithButton from 'components/lazy-blocks/pdf-banner-with-button';
import HubspotFormWrapper from 'components/lazy-blocks/hubspot-form-wrapper';
import BannerWithButton from 'components/lazy-blocks/banner-with-button';

import 'components/lazy-blocks/comprasion-table/comprasion-table.scss';
import 'components/lazy-blocks/image-gallery/image-gallery.scss';

const PDFPost = ({ data: { wpPdfResource: data }, pageContext, location }) => {
  useImageGallery();

  useIntersectionObserver('.use-intersection-observer', {
    rootMargin: '200px',
  });

  // https://github.com/remarkablemark/html-react-parser#htmlparser2
  // The library does parsing on client side differently from server side
  // it results in having a need of passing htmlparser2 to adjust behavior
  // according to the client side behavior
  const reactedContent = parse(pageContext.content, {
    htmlparser2: {
      lowerCaseAttributeNames: true,
    },
    // eslint-disable-next-line consistent-return,react/no-unstable-nested-components
    replace: (domNode) => {
      const props = attributesToProps(domNode.attribs);
      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'highlightedlist':
            const items = JSON.parse(props.items);
            return <HighlightedList title={props.title} items={items} />;
          case 'compacttitleandvideo':
            const videoAutoplay = !!Number(props.videoautoplay);
            const videobackgroundimage = JSON.parse(props.videobackgroundimage);
            return (
              <CompactTitleAndVideo
                title={props.title}
                description={props.description}
                quote={props.quote}
                video={props.video}
                isVideoAutoplay={videoAutoplay}
                videoBackgroundImage={videobackgroundimage}
              />
            );
          case 'pdfbannerwithbutton':
            return (
              <PdfBannerWithButton
                title={props.title}
                description={props.description}
                url={props.url}
                buttonText={props.buttontext}
              />
            );
          case 'hubspotform':
            return (
              <HubspotFormWrapper
                title={props.title}
                description={props.description}
                isCompact={props['is-compact']}
                formId={props['form-id']}
                id={props.id}
              />
            );
          case 'bannerwithbutton':
            return (
              <BannerWithButton
                title={props.title}
                description={props.description}
                buttonText={props['button-text']}
                buttonUrl={props['button-url']}
                isCompact={props['is-compact']}
                background={props.background}
              />
            );
          default:
            return undefined;
        }
      }
    },
  });
  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      headerLayout={data.acf.headerLayout}
      headerTheme={data.acf.headerTheme}
      footerTheme={data.acf.footerTheme}
      withBanner={pageContext.showBanner}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Hero
        title={data.acf.hero.title}
        description={data.acf.hero.description}
        background={data.acf.hero.background}
        formTitle={data.acf.hero.formTitle}
        formButtonText={data.acf.hero.formButtonText}
        pageUrl={location.pathname}
        fileUrl={data.acf.file.guid}
        postId={data.databaseId}
        displayFollowUpPage={data.acf.hero.displayFollowUpPage}
        followUpRedirectUrl={data.acf.hero.followUpRedirectUrl}
        formId={data.acf.hero.formId}
      />
      <Content content={reactedContent} />
    </LayoutMain>
  );
};

export default PDFPost;

export const query = graphql`
  query ($id: String!) {
    wpPdfResource(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      acf {
        headerLayout
        headerTheme
        footerTheme
        hero {
          title
          description
          background
          formTitle
          formButtonText
          displayFollowUpPage
          followUpRedirectUrl
          formId
        }
        file {
          guid
        }
      }
      ...wpPdfResourceSeo
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/shared/link';

const FormNotice = ({ className }) => (
  <p className={classNames('form-notice', className)}>
    By submitting this form, you agree to our <Link to="/terms-of-use/">Terms of Use</Link> and{' '}
    <Link to="/privacy/">Privacy Policy</Link>
  </p>
);

FormNotice.propTypes = {
  className: PropTypes.string,
};

FormNotice.defaultProps = {
  className: null,
};

export default FormNotice;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Heading from 'components/shared/heading';
import Button from 'components/shared/button';

import useVideo from 'hooks/use-video';

import VolumeControl from 'icons/volume-control.inline.svg';
import PlayIcon from 'icons/play.inline.svg';
import QuoteIcon from 'icons/quote.inline.svg';

import styles from './compact-title-and-video.module.scss';

const cx = classNames.bind(styles);

const quoteAnimationVariants = {
  initial: {
    y: 0,
    opacity: 0,
    transition: { duration: 0.5, ease: [0.21, 0.49, 0.33, 1] },
  },
  appear: {
    y: '-50%',
    opacity: 1,
    transition: { duration: 0.5, ease: [0.21, 0.49, 0.33, 1] },
  },
};

const CompactTitleAndVideo = (props) => {
  const { title, description, quote, video, isVideoAutoplay, videoBackgroundImage } = props;

  const [sectionRef, inView] = useInView({ threshold: 1, triggerOnce: true });
  const [isVideoStartedPlaying, setIsVideoStartedPlaying] = useState(false);

  const { videoWrapperRef, videoRef, isUnmuteVisible, isVideoMuted, unmute, handleVideoEnd } =
    useVideo();

  const handlePlayButtonClick = () => setIsVideoStartedPlaying(true);

  const isVisibleQuote = !isVideoStartedPlaying && quote && !isVideoAutoplay;

  return (
    <section className={cx('wrapper')} ref={sectionRef}>
      <div className="container">
        {(title || description) && (
          <div className={cx('head')}>
            {title && (
              <Heading className={cx('title')} tag="h2" size="xl">
                {title}
              </Heading>
            )}

            {description && <p className={cx('description')}>{description}</p>}
          </div>
        )}

        <div className={cx('video-wrapper')} ref={videoWrapperRef}>
          <motion.div
            className={cx('quote-wrapper')}
            initial="initial"
            variants={quoteAnimationVariants}
            animate={inView && isVisibleQuote ? 'appear' : 'initial'}
          >
            <figure className={cx('quote')}>
              <QuoteIcon className={cx('quote-icon')} />
              <blockquote className={cx('quote-text')}>
                <p>{quote}</p>
              </blockquote>
            </figure>
          </motion.div>

          <div className={cx('video-background')}>
            <img src={videoBackgroundImage.url} alt={videoBackgroundImage.alt} loading="lazy" />
          </div>

          {isVideoAutoplay && (
            <>
              <video
                className={cx('video')}
                muted={isVideoMuted}
                controls={!isVideoMuted}
                playsInline
                preload="none"
                onEnded={handleVideoEnd}
                ref={videoRef}
              >
                <source src={video} type="video/mp4" />
              </video>
              {isUnmuteVisible && (
                <Button
                  className={cx('button-mute')}
                  theme="primary"
                  size="sm"
                  data-type="video-mute-button"
                  onClick={unmute}
                >
                  <VolumeControl />
                  enable audio
                </Button>
              )}
            </>
          )}

          {!isVideoAutoplay && (
            <>
              {!isVideoStartedPlaying && (
                <Button
                  className={cx('play-button')}
                  theme="tertiary"
                  onClick={handlePlayButtonClick}
                >
                  <PlayIcon />
                </Button>
              )}

              {isVideoStartedPlaying && (
                <video className={cx('video')} autoPlay controls loop playsInline>
                  <source src={video} type="video/mp4" />
                </video>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

CompactTitleAndVideo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  quote: PropTypes.string,
  video: PropTypes.string.isRequired,
  isVideoAutoplay: PropTypes.bool,
  videoBackgroundImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
};

CompactTitleAndVideo.defaultProps = {
  description: null,
  quote: null,
  isVideoAutoplay: false,
};

export default CompactTitleAndVideo;
